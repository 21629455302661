import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';

export default class ArticleHeader extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        readTimeMinutes: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
    };

    render() {
        const {
            title,
            readTimeMinutes,
        } = this.props;

        return (
            <header style={{ clear: 'both' }}>
                <Header as="h1">{title}</Header>
                <p>
                    Estimated read time:
                    {' '}
                    {readTimeMinutes}
                    {' '}
                    minutes
                </p>
            </header>
        );
    }
}
